import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    Spacecloud: westack {
      tool(id: "space-cloud") {
        ...ToolInfo
      }
    }
    Insomnia: westack {
      tool(id: "insomnia") {
        ...ToolInfo
      }
    }
    Triangula: westack {
      tool(id: "triangula") {
        ...ToolInfo
      }
    }
    Github_Audio: westack {
      tool(id: "github-audio") {
        ...ToolInfo
      }
    }
    Gitbug: westack {
      tool(id: "git-bug") {
        ...ToolInfo
      }
    }
    Twirp: westack {
      tool(id: "twirp") {
        ...ToolInfo
      }
    }
    githubtheme: westack {
      tool(id: "github-vscode-theme") {
        ...ToolInfo
      }
    }
    Superlinter: westack {
      tool(id: "super-linter") {
        ...ToolInfo
      }
    }
    Notea: westack {
      tool(id: "notea") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Spacecloud.tool} mdxType="Tool" />
    <Tool tool={props.data.Insomnia.tool} mdxType="Tool" />
    <Tool tool={props.data.Triangula.tool} mdxType="Tool" />
    <Tool tool={props.data.Github_Audio.tool} mdxType="Tool" />
    <Tool tool={props.data.Gitbug.tool} mdxType="Tool" />
    <Tool tool={props.data.Twirp.tool} mdxType="Tool" />
    <Tool tool={props.data.githubtheme.tool} mdxType="Tool" />
    <Tool tool={props.data.Superlinter.tool} mdxType="Tool" />
    <Tool tool={props.data.Notea.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      